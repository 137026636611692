import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getData from './AirTableData'; // Ensure the path to getData is correct

import { Input, Button, Box, Icon } from '@quarkly/widgets';
import ValidatorSpinner from './ValidatorSpinner';
import { FaRegArrowAltCircleRight } from "react-icons/fa";

const PromoCodeValidator = ({
	onValidation,
	override
}) => {
	const [promoCode, setPromoCode] = useState('');
	const [isValid, setIsValid] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const validatePromoCode = async () => {
		setIsLoading(true);
		setIsValid(null); // Reset validity state on new submission

		const options = {
			filterByFormula: `{PromoCode} = "${promoCode}"` // Adjust to match Airtable field

		};
		getData({
			base: 'appbrKbvUmNYS19iS',
			// Replace with your Airtable Base ID
			table: 'PromoCodes',
			// Replace with your Airtable Table name
			options,
			onRecords: records => {
				setIsLoading(false);

				if (!records.records || records.records.length === 0) {
					setIsValid(false); // Promo code not found

					onValidation(false, null); // Pass null for discount

					return;
				}

				const promo = records.records[0].fields;
				const isActive = promo.Active; // Assumes "Active" field is calculated in Airtable

				const discountPercent = promo.DiscountPercent || 0; // Default to 0 if not available

				setIsValid(isActive);
				onValidation(isActive, discountPercent, promoCode); // Pass the discount percent as well
			},
			onError: error => {
				console.error('Error fetching promo code:', error);
				setIsLoading(false);
				setIsValid(false); // Treat as invalid on error

				onValidation(false, null); // Pass null for discount on error
			}
		});
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (promoCode.trim() !== '') {
			validatePromoCode();
		}
	};

	const inputStyle = {
		border: `2px solid ${isValid === null ? 'gray' : isValid ? 'green' : 'red'}`,
		padding: '8px',
		borderRadius: '4px'
	};
	return <form onSubmit={handleSubmit} style={{
		display: 'flex',
		flexDirection: 'column',
		gap: '8px'
	}}>
		            
		<Box style={{
			display: 'flex',
			position: 'relative'
		}}>
			                
			<Input
				type="text"
				value={promoCode}
				onChange={e => setPromoCode(e.target.value.trim())}
				disabled={isLoading}
				{...override('PromoCodeInput')}
			/>
			                
			<Button type="submit" disabled={isLoading} style={{
				width: "20%",
				borderRadius: "0 10px 10px 0",
				margin: "10px 0",
				background: "#487CCF",
				opacity: "0.8"
			}} {...override('PromoCodeCheckButton')}>
				                    
				{!isLoading && <Icon category="fa" icon={FaRegArrowAltCircleRight} size="25.5px" color="#ffffff" />}
				                    
				{isLoading && <ValidatorSpinner />}
				                
			</Button>
			                
			{isValid === false && <p style={{
				color: 'red',
				position: 'absolute',
				bottom: '-30px',
				right: '0'
			}}>
				{"Hibás vagy lejárt promókód!"}
			</p>}
			                
			{isValid === true && <p style={{
				color: 'green',
				position: 'absolute',
				bottom: '-30px',
				right: '0'
			}}>
				{"Érvényes promókód!"}
			</p>}
			            
		</Box>
		        
	</form>;
};

PromoCodeValidator.propTypes = {
	onValidation: PropTypes.func.isRequired // Callback for validation result

};
export default PromoCodeValidator;
/* How to use it EXAMPLE

<PromoCodeValidator 
    onValidation={(isValid, discount) => {
        if (isValid) {
            console.log(`Promo code is valid! Discount: ${discount}%`);
        } else {
            console.log('Promo code is invalid or expired.');
        }
    }} 
/>
*/