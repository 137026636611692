import React, { useState, useEffect } from 'react';
import atomize from "@quarkly/atomize";
import { Box, Text, Button, Input } from "@quarkly/widgets";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { RingLoader } from 'react-spinners';
import { useOverrides } from '@quarkly/components';
import TagManager from "react-gtm-module"; //const stripePromise = loadStripe('pk_test_51IEAz7GfoabQmGT28UVk6leNEoMHQikwnAXbhvt8N7zp21xabcO2sEOWGdwWojgg3J3Gg30HI3eyCLmEMpGSz7bg00QPLpHSYh');

const CheckoutForm = ({
	price,
	itemName,
	currencyCode,
	itemDescription,
	onSuccess,
	clientSecret,
	override,
	isTrueSession
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [isProcessing, setIsProcessing] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	const handleSubmit = async event => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setIsProcessing(true);
		const {
			error,
			paymentIntent
		} = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: window.location.href,
				payment_method_data: {
					billing_details: {
						name: `${firstName} ${lastName}`,
						email
					}
				}
			},
			redirect: 'if_required'
		});

		if (error) {
			console.error(error);
			setErrorMsg(error.message);
			setIsProcessing(false);
			return;
		} else {
			setErrorMsg('');
		}

		const details = {
			payer: {
				name: {
					given_name: firstName,
					surname: lastName
				},
				email_address: email
			}
		};

		if (paymentIntent?.status === 'succeeded') {
			onSuccess(details, paymentIntent);
		}

		setIsProcessing(false);
	};

	return <form onSubmit={handleSubmit} style={{
		marginTop: '20px'
	}} {...override('Form')}>
		{isTrueSession && <Box {...override('FormWrapper')}>
			<Box {...override('CardElementBox')}>
				<Box {...override('NameInput')}>
					<Input {...override('LastNameInput')} placeholder="Vezetéknév" value={lastName} onChange={e => setLastName(e.target.value)} />
					<Input {...override('FirstNameInput')} placeholder="Keresztnév" value={firstName} onChange={e => setFirstName(e.target.value)} />
				</Box>
				<Input {...override('EmailInput')} placeholder="Email Cím" value={email} onChange={e => setEmail(e.target.value)} />
				{clientSecret && <PaymentElement {...override('PaymentElement')} />}
			</Box>
			<Box {...override("StripeButtonWrapper")}>
				<Button {...override('SubmitButton')} type="submit" disabled={!stripe || isProcessing || !isTrueSession}>
					{isProcessing ? 'Folyamatban…' : 'Fizetés'}
				</Button>
				{errorMsg && <p style={{
					color: 'red'
				}}>
					{errorMsg}
				</p>}
			</Box>
		</Box>}
	</form>;
};

const Stripe = ({
	name,
	email,
	clientId,
	price = 1,
	itemName = "test item",
	currencyCode = "gbp",
	itemDescription,
	onSuccess,
	isTrueSession,
	locale = "hu",
	...props
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [clientSecret, setClientSecret] = useState('');
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const stripePromise = loadStripe(clientId);
	const [purchaseInitSent, setPurchaseInitSent] = useState('false');
	useEffect(() => {
		// Fetch client secret from backend
		const fetchClientSecret = async () => {
			const response = await fetch('https://teszt.sziklaybence.com/.netlify/functions/create-stripe-payment-intent', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					amount: parseInt(price * 100),
					currency: currencyCode,
					itemName,
					itemDescription,
					email
				})
			});
			const data = await response.json();
			setClientSecret(data.clientSecret);
			setIsLoading(false);
		};

		fetchClientSecret();
	}, [price, currencyCode, itemName, itemDescription, email]);

	const sendInitPurhcaseEvent = () => {
		const _sessionId = localStorage.getItem('sessionId');

		const tagManagerArgs = {
			dataLayer: {
				event: 'initialise purchase',
				// Use a specific event name
				transactionId: _sessionId
			}
		};
		TagManager.dataLayer(tagManagerArgs);
		if (!purchaseInitSent) sendInitPurhcaseEvent();
		setPurchaseInitSent(true); //console.log("init purchase event ", tagManagerArgs);
	};

	if (isLoading || !clientSecret) {
		return <Box display="grid" justify-items="center">
			<RingLoader color="#487CCF" />
			<Text margin="30px" font="--lead">
				Betöltés...
			</Text>
		</Box>;
	}

	return <Elements stripe={stripePromise} options={{
		clientSecret,
		locale
	}}>
		<Box {...rest}>
			<CheckoutForm
				price={price}
				itemName={itemName}
				currencyCode={currencyCode}
				itemDescription={itemDescription}
				onSuccess={onSuccess}
				clientSecret={clientSecret}
				override={override}
				isTrueSession={isTrueSession}
			/>
			{children}
		</Box>
	</Elements>;
};

const defaultProps = {
	price: 100,
	currencyCode: 'USD'
};
const overrides = {
	PaymentRequestButton: {
		kind: 'Box',
		props: {
			margin: '20px 0'
		}
	},
	Form: {
		kind: 'Box',
		props: {}
	},
	CardElementBox: {
		kind: 'Box',
		props: {
			margin: '20px 0'
		}
	},
	PaymentElement: {
		kind: 'Box',
		props: {}
	},
	SubmitButton: {
		kind: 'Button',
		props: {
			"background": "--color-vividOrange",
			"width": "100%"
		}
	},
	FirstNameInput: {
		kind: 'Input',
		props: {
			margin: '10px 0',
			placeholder: 'First Name',
			"width": "50%",
			"border-width": "1px",
			"transition": "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
			"border-color": "#e6e6e6",
			"box-shadow": "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)",
			"border-radius": "5px",
			"focus-border-color": "#0573e180",
			"focus-box-shadow": "0px 1px 1px 3px #0573e140",
			"required": true,
			"type": "text"
		}
	},
	LastNameInput: {
		kind: 'Input',
		props: {
			margin: '10px 0',
			placeholder: 'Last Name',
			"width": "50%",
			"border-width": "1px",
			"transition": "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
			"border-color": "#e6e6e6",
			"box-shadow": "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)",
			"border-radius": "5px",
			"focus-border-color": "#0573e180",
			"focus-box-shadow": "0px 1px 1px 3px #0573e140",
			"required": true,
			"type": "text"
		}
	},
	EmailInput: {
		kind: 'Input',
		props: {
			margin: '10px 0',
			placeholder: 'Email Address',
			"width": "100%",
			"border-width": "1px",
			"transition": "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
			"border-color": "#e6e6e6",
			"box-shadow": "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)",
			"border-radius": "5px",
			"focus-border-color": "#0573e180",
			"focus-box-shadow": "0px 1px 1px 3px #0573e140",
			"required": true,
			"type": "email"
		}
	},
	"NameInput": {
		"props": {
			"display": "flex"
		}
	}
};
export default atomize(Stripe)({
	name: "Stripe",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "QuarklyStripeButton — A custom Stripe button component"
	},
	propInfo: {
		clientId: {
			title: "Client ID",
			control: "input",
			category: "Main",
			weight: 1
		},
		name: {
			title: "Name",
			control: "input",
			category: "Main",
			weight: 1
		},
		email: {
			title: "Email",
			control: "input",
			category: "Main",
			weight: 1
		},
		price: {
			title: "Price",
			control: "input",
			category: "Main",
			weight: 1
		},
		itemName: {
			title: "Item Name",
			control: "input",
			category: "Main",
			weight: 1
		},
		currencyCode: {
			title: "Currency Code",
			control: "input",
			category: "Main",
			weight: 1
		},
		itemDescription: {
			title: "Item Description",
			control: "input",
			category: "Main",
			weight: 1
		}
	},
	overrides,
	defaultProps
});