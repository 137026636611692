import React from "react";
import { useOverrides } from "@quarkly/components";
import { Box } from "@quarkly/widgets";
import { SyncLoader } from 'react-spinners';
const defaultProps = {};
const overrides = {};

const ValidatorSpinner = props => {
	const {
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		<SyncLoader color="#FFFFFF" size="8px" margin="0px" speedMultiplier="0.6" />
	</Box>;
};

Object.assign(ValidatorSpinner, { ...Box,
	defaultProps,
	overrides
});
export default ValidatorSpinner;