import { useCallback } from 'react';

const useUpdatePromoCodeUsage = () => {
	const updatePromoCodeUsage = useCallback(async promoRecordId => {
		if (!promoRecordId) {
			console.error("Promo code record ID is required.");
			return;
		}

		try {
			const response = await fetch('https://teszt.sziklaybence.com/.netlify/functions/incrementPromoCodeUsage', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					recordId: promoRecordId
				})
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			console.log('Promo code usage updated successfully:', data);
			return data;
		} catch (error) {
			console.error('Error updating promo code usage:', error);
			throw error;
		}
	}, []);
	return updatePromoCodeUsage;
};

export default useUpdatePromoCodeUsage;