import React, { useEffect, useState } from "react";
import { useOverrides } from "@quarkly/components";
import { Box, Text } from "@quarkly/widgets";
import { Tooltip } from 'react-tooltip';
const defaultProps = {
	popupContent: "Default text",
	maxWidth: "80%"
};
const overrides = {};
const propInfo = {
	popupContent: {
		title: "Popup Content",
		description: "Text for popup window.",
		control: "input",
		category: "Main",
		weight: 1
	},
	maxWidth: {
		title: "Max Width of tooltip",
		description: "",
		control: "input",
		category: "Main",
		weight: 1
	}
};

const ReactTooltip = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [elementClass, setElementClass] = useState('');
	useEffect(() => {
		setElementClass(`anchor-element${Math.floor(Math.random(0, 9) * 100000)}`);
	}, []);
	return <Box width="fit-content" {...rest}>
		<Box className={elementClass}>
			{children}
		</Box>
		<Tooltip anchorSelect={`.${elementClass}`} place="top" data-tooltip-position-strategy="fixed" style={{
			maxWidth: props.maxWidth
		}}>
			<Text font="--base">
				{props.popupContent}
			</Text>
		</Tooltip>
	</Box>;
};

Object.assign(ReactTooltip, { ...Box,
	defaultProps,
	overrides,
	propInfo
});
export default ReactTooltip;