import React, { useState, useEffect } from 'react';
import atomize from "@quarkly/atomize";
import { Box, Input, Text, Icon, Link, Button } from '@quarkly/widgets';
import ReactTooltip from "./ReactTooltip";
import { FaCcPaypal, FaExpeditedssl } from "react-icons/fa";

const PaymentFormFree = ({
	price,
	clientId,
	itemName,
	itemDescription,
	currencyCode,
	sessionId,
	onPaymentSuccess,
	isTrueSession,
	isChecking,
	handleEmailInputChange,
	handleNameInputChange,
	override,
	...props
}) => {
	const enterNameText = <Box>
		      
		<Text font="--font-base">
			 Nincsenek rejtett díjak, nincsenek előfizetések – csak a teszteredmény.
		</Text>
		      
		<Box
			display="grid"
			margin-top="45px"
			grid-template-columns="repeat(3, 1fr)"
			justify-items="center"
			align-items="center"
			{...override('PaymentFooterWrapper')}
		>
			        
			<ReactTooltip border="none" {...override('CustomTooltipIcon1')}>
				          
				<Icon
					category="fa"
					icon={FaCcPaypal}
					size="64px"
					color="#00457C"
					{...override('PaymentFooterIcon1')}
				/>
				        
			</ReactTooltip>
			        
			<ReactTooltip border="none" {...override('CustomTooltipIcon2')}>
				          
				<Icon
					category="fa"
					icon={FaExpeditedssl}
					size="64px"
					color="#00457C"
					{...override('PaymentFooterIcon2')}
				/>
				        
			</ReactTooltip>
			        
			<ReactTooltip border="none" {...override('CustomTooltipIcon3')}>
				          
				<Icon
					category="fa"
					icon={FaExpeditedssl}
					size="64px"
					color="#00457C"
					{...override('PaymentFooterIcon3')}
				/>
				        
			</ReactTooltip>
			      
		</Box>
		    
	</Box>;
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [details, setDetails] = useState({
		payer: {
			email_address: "n/a",
			name: {
				given_name: "n/a",
				surname: "n/a"
			}
		}
	});
	useEffect(() => {
		setDetails({
			payer: {
				email_address: email,
				name: {
					given_name: firstName,
					surname: lastName
				}
			}
		}); //console.log("details changed");
	}, [email, firstName, lastName]);
	const data = {};

	const handleOnSuccessButton = (details, data) => {
		const emailAddress = details.payer.email_address;
		const fullName = `${details.payer.name.given_name} ${details.payer.name.surname}`;
		onPaymentSuccess(details, data); //console.log(`handleOnSuccessButton - ${emailAddress}, ${fullName}`);
		//console.log(details);
	};

	return <Box>
		      
		{isChecking && <Text font="--font-lead" color="--color-amaranth">
			Teszt hitelesítése folyamatban...
		</Text>}
		      
		{!isTrueSession && !isChecking && <Text font="--font-lead" color="--color-amaranth">
			 Kitöltött teszt nem található!
		</Text>}
		      
		<Text font="--font-base" {...override('Data Privacy')}>
			Adataidat biztonságosan kezeljük. További részletekért tekintsd meg az 
			<Link href="https://sziklaybence.com/terms" {...override('PaypalLink')}>
				{"Általános Szerződési Feltételeket."}
			</Link>
			.
		</Text>
		      
		{
			/*<Stripe
    key={"REFRESH" + price}
    name={name}
    email={email}
    clientId={clientId}
    price={price}
    itemName={itemName}
    currencyCode={currencyCode}
    itemDescription={`${itemDescription} sid=${sessionId}`}
    onSuccess={(details, data) => onPaymentSuccess(details, data)}
    isTrueSession={isTrueSession}
    {...override('PayPal')}
   />*/
		}
		      
		{isTrueSession && <>
			          
			<form onSubmit={e => {
				e.preventDefault(); // Prevent default to handle custom behavior if needed

				handleOnSuccessButton(details, data); // Call the success handler
			}}>
				            
				<Box {...override('CardElementBox')}>
					              
					<Box {...override('NameInput')}>
						                
						<Input {...override('LastNameInput')} value={lastName} onChange={e => setLastName(e.target.value)} />
						                
						<Input {...override('FirstNameInput')} value={firstName} onChange={e => setFirstName(e.target.value)} />
						              
					</Box>
					              
					<Input {...override('EmailInput')} value={email} onChange={e => setEmail(e.target.value)} />
					            
				</Box>
				            
				<Button {...override('SubmitButton')} type="submit" />
				            
				<Text font="--lead" text-align="center">
					{enterNameText}
				</Text>
				          
			</form>
			        
		</>}
		    
	</Box>;
};

export default atomize(PaymentFormFree)({
	name: "PaymentForm",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "PaymentForm — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});