import React from "react";
import { useOverrides } from "@quarkly/components";
import { Image, Text, Box, LinkBox } from "@quarkly/widgets";
const defaultProps = {
	"max-width": "220px",
	"background": "--color-darkL1",
	"max-height": "50px",
	"align-items": "center",
	"justify-content": "center",
	"padding": "5px 5px 5px 5px",
	"href": "https://wavedropper.com",
	"target": "_blank",
	"opacity": "0.5",
	"flex-direction": "row",
	"align-content": "center",
	"display": "flex",
	"margin": "5px 5px 5px 5px"
};
const overrides = {
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/6602d5961d712a0023322e52/images/wavedropper_logo_w.svg?v=2024-06-19T17:44:07.026Z",
			"display": "block",
			"width": "45px"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"justify-content": "center",
			"align-content": "center",
			"justify-items": "center",
			"padding": "0px 0px 0px 16px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "#ffffff",
			"text-decoration-line": "initial",
			"font": "300 10px --fontFamily-googlePoppins",
			"text-align": "center",
			"margin": "0 0px 0px 0px",
			"children": "Created by"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"color": "#ffffff",
			"text-decoration-line": "initial",
			"font": "300 15px --fontFamily-googlePoppins",
			"text-align": "center",
			"margin": "0 0px 0 0px",
			"children": "Wavedropper"
		}
	}
}; // Define the site credits

const siteCredits = `
%c
░██╗░░░░░░░██╗░█████╗░██╗░░░██╗███████╗██████╗░██████╗░░█████╗░██████╗░██████╗░███████╗██████╗░
░██║░░██╗░░██║██╔══██╗██║░░░██║██╔════╝██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
░╚██╗████╗██╔╝███████║╚██╗░██╔╝█████╗░░██║░░██║██████╔╝██║░░██║██████╔╝██████╔╝█████╗░░██████╔╝
░░████╔═████║░██╔══██║░╚████╔╝░██╔══╝░░██║░░██║██╔══██╗██║░░██║██╔═══╝░██╔═══╝░██╔══╝░░██╔══██╗
░░╚██╔╝░╚██╔╝░██║░░██║░░╚██╔╝░░███████╗██████╔╝██║░░██║╚█████╔╝██║░░░░░██║░░░░░███████╗██║░░██║
░░░╚═╝░░░╚═╝░░╚═╝░░╚═╝░░░╚═╝░░░╚══════╝╚═════╝░╚═╝░░╚═╝░╚════╝░╚═╝░░░░░╚═╝░░░░░╚══════╝╚═╝░░╚═╝
                                                                                                
 Created by Wavedropper
 🌐 https://wavedropper.com
`; // Define the styles

const style = `
color: #3A3FA7;
font-size: 12px;
font-weight: bold;
`; // Log the credits to the console

console.log(siteCredits, style);

const CreatorLogo = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <LinkBox {...rest}>
		<Image {...override("image")} />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		{children}
	</LinkBox>;
};

Object.assign(CreatorLogo, { ...LinkBox,
	defaultProps,
	overrides
});
export default CreatorLogo;