import React, { useState, useEffect, useRef } from 'react';
import atomize from '@quarkly/atomize';
import { Box, Input, Text, Image, Icon, Strong, Section, Link } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components';
import getData from "./AirTableData";
import { scrollToId } from "./UxUtils";
import { sendEvent } from "./Footer";
import PricePanel from "./PricePanel";
import PaymentForm from "./PaymentForm";
import PaymentFormFree from "./PaymentFormFree";
import FetchPdfUrls from "./FetchPdfUrls";
import Spinner from './Spinner';
import VideoDemo from './VideoDemo';
import McSubscribeCheckbox from './McSubscribeCheckbox';
import PromoCodeValidator from './PromoCodeValidator';
import useUpdatePromoCodeUsage from './UseUpdatePromoCodeUsage';
import { FaCloudDownloadAlt, FaBrain } from "react-icons/fa";
const overrides = {
	"SideLabel": {
		"props": {
			"color": "#ffffff",
			"background": "--color-secondary",
			"font": "normal 300 13px/1.5 --fontFamily-googlePoppins",
			"position": "absolute",
			"transform": "rotate(90deg)",
			"right": "-38px",
			"left": "auto",
			"top": "70px",
			"bottom": "auto",
			"border-radius": "0px 0px 12px 12px",
			"padding": "0px 10px 0px 10px",
			"margin": "16px -2px 16px 0px",
			"children": "sziklaybence.com"
		}
	},
	"button": {
		"props": {
			"background": "--color-amaranth",
			"display": "flex",
			"font": "--headline2",
			"height": "64px",
			"width": "64px",
			"justify-content": "center",
			"align-items": "center",
			"align-self": "center",
			"position": "absolute",
			"right": 0,
			"padding": "0 0 0 0",
			"border-radius": "30px 0 0 30px"
		}
	},
	"button1": {
		"props": {
			"background": "--color-amaranth",
			"display": "flex",
			"font": "--headline2",
			"height": "64px",
			"width": "64px",
			"justify-content": "center",
			"align-items": "center",
			"align-self": "center",
			"position": "absolute",
			"right": 0,
			"padding": "0 0 0 0",
			"border-radius": "30px 0 0 30px"
		}
	},
	"button2": {
		"props": {
			"background": "--color-amaranth",
			"display": "flex",
			"font": "--headline2",
			"height": "64px",
			"width": "64px",
			"justify-content": "center",
			"align-items": "center",
			"align-self": "center",
			"position": "absolute",
			"right": 0,
			"padding": "0 0 0 0",
			"border-radius": "30px 0 0 30px"
		}
	},
	"Download Ico": {
		"props": {
			"category": "fa",
			"icon": FaCloudDownloadAlt,
			"color": "--amaranth",
			"font": "64px Lexend, sans-serif"
		}
	},
	"Total Price Anim": {
		"props": {
			"position": "sticky",
			"right": 0,
			"left": 0,
			"top": 0,
			"z-index": "3"
		}
	},
	"PricePanel": {
		"props": {
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-secondary",
			"margin": "30px 0px 0px 0px",
			"background": "#ffffff"
		}
	},
	"FirstNameInput": {
		kind: 'Input',
		props: {
			margin: '10px 0',
			placeholder: 'Keresztnév',
			"name": "firstname",
			"autocomplete": true,
			"width": "50%",
			"border-width": "1px",
			"transition": "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
			"border-color": "#e6e6e6",
			"box-shadow": "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)",
			"border-radius": "5px",
			"focus-border-color": "#0573e180",
			"focus-box-shadow": "0px 1px 1px 3px #0573e140",
			"required": true,
			"type": "text"
		}
	},
	"LastNameInput": {
		kind: 'Input',
		props: {
			margin: '10px 0',
			placeholder: 'Vezetéknév',
			"name": 'lastname',
			"autocomplete": true,
			"width": "50%",
			"border-width": "1px",
			"transition": "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
			"border-color": "#e6e6e6",
			"box-shadow": "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)",
			"border-radius": "5px",
			"focus-border-color": "#0573e180",
			"focus-box-shadow": "0px 1px 1px 3px #0573e140",
			"required": true,
			"type": "text"
		}
	},
	"EmailInput": {
		kind: 'Input',
		props: {
			"margin": '10px 0',
			"placeholder": 'Email Cím',
			"name": 'email',
			"autocomplete": true,
			"aria-label": 'email',
			"width": "100%",
			"border-width": "1px",
			"transition": "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
			"border-color": "#e6e6e6",
			"box-shadow": "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)",
			"border-radius": "5px",
			"focus-border-color": "#0573e180",
			"focus-box-shadow": "0px 1px 1px 3px #0573e140",
			"required": true,
			"type": "email"
		}
	},
	"PromoCodeInput": {
		kind: 'Input',
		props: {
			margin: '10px 0',
			placeholder: 'Promóciós kód',
			"width": "80%",
			"border-width": "1px",
			"transition": "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
			"border-color": "#e6e6e6",
			"box-shadow": "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)",
			"border-radius": "5px",
			"focus-border-color": "#0573e180",
			"focus-box-shadow": "0px 1px 1px 3px #0573e140",
			"required": true,
			"type": "text"
		}
	}
};

const PaymentWrapper = ({
	clientId,
	itemName,
	currencyCode,
	itemDescription,
	...props
}) => {
	const [paymentCompleted, setPaymentCompleted] = useState(false);
	const [isTrueSession, setIsTrueSession] = useState(false);
	const [sessionId, setSessionId] = useState('');
	const [showResults, setShowResults] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [result, setResult] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isChecking, setIsChecking] = useState(true);
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, {});
	const isEmpty = !children || children.length === 0;
	const [price, setPrice] = useState(0); // Create refs for name and email

	const nameRef = useRef();
	const emailRef = useRef();
	const sessionIdRef = useRef();
	const [addons, setAddons] = useState({});
	const addonsRef = useRef();
	const [trigger, setTrigger] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [_promoCode, setPromoCode] = useState('');
	const updatePromoCodeUsage = useUpdatePromoCodeUsage();
	const [discountPercentage, setDiscountPercentage] = useState(0);
	useEffect(() => {
		if (paymentCompleted && _promoCode) {
			updatePromoCodeUsage(_promoCode);
		}
	}, [_promoCode, paymentCompleted]);
	useEffect(() => {
		const maxRetries = 50; // Maximum number of retries

		const intervalDuration = 100; // Interval duration in milliseconds

		let retries = 0;
		const intervalId = setInterval(() => {
			const _sessionId = getSessionId();

			if (_sessionId) {
				sessionIdRef.current = _sessionId;
				setIsTrueSession(true);
				clearInterval(intervalId);
				setIsChecking(false);
			} else if (retries >= maxRetries) {
				clearInterval(intervalId);
				setIsChecking(false);
			}

			retries++;
		}, intervalDuration);
		return () => clearInterval(intervalId); // Cleanup interval on unmount
	}, []);

	const updatePurchasedItems = items => {
		setAddons(items);
		addonsRef.current = items; //console.log(items);
	};

	const getSessionId = () => {
		const sessionData = localStorage.getItem('sessionData'); // Check if sessionData is not null

		if (sessionData) {
			try {
				const {
					value,
					expires,
					isComplete
				} = JSON.parse(sessionData); // Check if the session data includes the expires information

				if (expires && new Date().getTime() < expires) {
					if (isComplete) {
						setSessionId(value);
						return value;
					} else {
						// Return null if isComplete is not true
						console.error('Incomplete Session');
						return null;
					}
				} else {
					// Clear the storage if the expiration is passed or no expiration data is found
					localStorage.removeItem('sessionData');
				}
			} catch (e) {
				// Handle cases where the session data is not in the expected JSON format
				console.log("Error parsing session data: ", e); // Optionally clear the invalid session data

				localStorage.removeItem('sessionData');
			}
		}

		return null;
	};

	useEffect(() => {
		nameRef.current = name;
		emailRef.current = email; //console.log("sid",sessionId);
	}, [name, email]);

	const fetchResult = async sessionId => {
		return new Promise((resolve, reject) => {
			getData({
				base: "appbrKbvUmNYS19iS",
				table: "SavedSessions",
				options: {
					view: "DefaultGrid",
					filterByFormula: `{SessionId} = "${sessionId}"`,
					fields: "Type,Wing" // Pass fields as a comma-separated string

				},
				onRecords: records => {
					//console.log("Records received:", records); // Log received records for debugging
					if (records.records.length > 0) {
						const pType = records.records[0].fields.Type;
						const pWing = records.records[0].fields.Wing;
						const output = {
							type: pType,
							wing: pWing
						};
						resolve(output);
					} else {
						reject(new Error("Session not found"));
					}
				},
				onError: error => {
					console.error("Error in fetchResult:", error); // Log the error for debugging

					reject(new Error(`Error fetching session: ${error.message}`));
				}
			});
		});
	};

	const onPaymentSuccess = async (details, data) => {
		// Accessing the name and email address
		const emailAddress = details.payer.email_address;
		const fullName = `${details.payer.name.given_name} ${details.payer.name.surname}`;
		setName(fullName);
		setFirstName(details.payer.name.given_name);
		setLastName(details.payer.name.surname);
		setEmail(emailAddress);
		setTrigger(true);
		scrollToId('topnav', 0, 0);

		if (price === 0) {
			updateSessionDetails(sessionIdRef.current, fullName, emailAddress, addonsRef.current);
		} else {
			updateSessionDetails(sessionIdRef.current, nameRef.current, emailRef.current, addonsRef.current);
		}

		setPaymentCompleted(true);
		sendEvent(tagManagerArgs); //console.log("Payment successful. Getting score...");

		try {
			const _result = await fetchResult(sessionIdRef.current); //console.log("Results:", _result); // Fixed typo from _results to _result


			setResult(_result);
			setShowResults(true);
		} catch (error) {
			console.error("Failed to get results:", error);
		}
	};

	const tagManagerArgs = {
		dataLayer: {
			event: 'purchase',
			transactionId: sessionId,
			value: price,
			tax: 0.00,
			currency: currencyCode,
			email: email,
			user_data: {
				email_address: email,
				// Ensure this is hashed if required by Meta
				//phone_number: phoneNumber, // Optional if available
				address: {
					first_name: firstName,
					// Optional if available
					last_name: lastName // Optional if available
					//city: city, // Optional
					//region: region, // Optional
					//postal_code: postalCode, // Optional
					//country: country, // Optional

				}
			},
			items: [{
				item_name: 'Személyiségteszt eredménye',
				// Optional for products
				quantity: 1,
				// Optional
				price: price,
				// Optional
				item_category: 'digital content',
				// Optional
				item_brand: 'SziklayBence' // Optional

			}]
		}
	};

	const updateSessionDetails = async (sessionId, name, email, addons) => {
		/*console.log("Updating session with:", {
  	sessionId,
  	name,
  	email,
  	addons
  });*/
		try {
			const response = await fetch('https://teszt.sziklaybence.com/.netlify/functions/updateSessionDetails', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					sessionId,
					name,
					email,
					addons
				})
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json(); //console.log('Success:', data);
			// Handle success response
		} catch (error) {
			console.error('Error:', error); // Handle error response
		}
	};

	const handleDataFromPricePanel = value => {
		setPrice(value);
	};

	const mcListUniqueId = '869da1a2b1';
	const mcTagName = 'Enneagram Test';
	return <Box {...rest} id="payment-wrapper">
		{!paymentCompleted ? <>
			<Box {...override('Content')}>
				<Box {...override('GottaPay')} onClick={() => scrollToId('payhere', 1000, 0)}>
					<Box {...override('GPCWrapper')}>
						<Text {...override('GottaPayLabel')} />
						<Icon {...override('GPIcon')} />
					</Box>
				</Box>
				<PricePanel
					{...override('PricePanel')}
					override={override}
					priceTotal={handleDataFromPricePanel}
					purchasedItems={updatePurchasedItems}
					priceBase={props.priceBase}
					priceMax={props.priceMax}
					priceAddon1={props.priceAddon1}
					priceAddon2={props.priceAddon2}
					priceAddon3={props.priceAddon3}
					discountAmount={discountPercentage}
				/>
				<Box {...override('Grouping')}>
					<Box {...override('PayTitleWrapper')}>
						<Text {...override('PaymentTitle')} />
					</Box>
					<McSubscribeCheckbox
						trigger={trigger}
						email={email}
						firstName={firstName}
						lastName={lastName}
						price={price}
						listId={mcListUniqueId}
						tagName={mcTagName}
						{...override('McSubscribeCheckbox')}
					/>
					{isEmpty && <Text>
						No content provided.
					</Text>}
					<Box id="payment-box" {...override('PaymentBox')}>
						<PromoCodeValidator onValidation={(isValid, discount, promoCode) => {
							if (isValid) {
								//console.log(`Promo code is valid! Discount: ${discount * 100}%`);
								setPromoCode(promoCode);
								setDiscountPercentage(discount); //console.log("discAmountSet to", discountPercentage)
								//console.log("We got promcode", promoCode)
							} else {
								setDiscountPercentage(0); //console.log('Promo code is invalid or expired.');
							}
						}} paymentCompleted={paymentCompleted} override={override} />
						{price > 0 && <PaymentForm
							name={name}
							email={email}
							price={price}
							clientId={clientId}
							itemName={itemName}
							itemDescription={itemDescription}
							currencyCode={currencyCode}
							sessionId={sessionId}
							onPaymentSuccess={onPaymentSuccess}
							isTrueSession={isTrueSession}
							isChecking={isChecking}
							override={override}
							{...override('PaymentFormnWrapper')}
						/>}
						{price === 0 && <PaymentFormFree
							price={price}
							clientId={clientId}
							itemName={itemName}
							itemDescription={itemDescription}
							currencyCode={currencyCode}
							sessionId={sessionId}
							onPaymentSuccess={onPaymentSuccess}
							isTrueSession={isTrueSession}
							isChecking={isChecking}
							override={override}
							{...override('PaymentFormnWrapper')}
						/>}
					</Box>
				</Box>
				<VideoDemo {...override('VideoDemo')} />
				{<Text {...override('DisclaimerBottomText')} />}
				{<Text {...override('SecurePaymentExplainer')} />}
			</Box>
			<Box {...override('DisclaimerSection')}>
				<Box {...override('Box1-Wrapper')}>
					<Text {...override('Box1-Text')} />
				</Box>
				<Box {...override('Box2-Wrapper')}>
					<Text {...override('Box2-Text')} />
				</Box>
				<Box {...override('Box3-Wrapper')}>
					<Text {...override('Box3s-Text')} />
				</Box>
			</Box>
		</> : !showResults ? <Box {...override('Content')}>
			{<Box display="grid" align-items="end" justify-items="center" {...override('loadingPG pay success wrapper')}>
				<Image {...override('Analimage')} />
				<Text font="--lead">
					A fizetés sikeres volt. Köszönjük!
				</Text>
			</Box>}
			{<Box display="grid" align-items="end" justify-items="center" {...override('loader wrapper')}>
				<Box transform="scale(3,2)">
					<Spinner {...override('AnaliLoader')}>
						<Icon
							category="fa"
							icon={FaBrain}
							size="64px"
							color="--amaranth"
							{...override('BrainIco')}
						/>
					</Spinner>
				</Box>
				<Text font="--lead">
					Teszt elemzése...
				</Text>
			</Box>}
		</Box> : <>
			<FetchPdfUrls
				type={result.type}
				wing={result.wing}
				addon1={addons.addon1}
				addon2={addons.addon2}
				addon3={addons.addon3}
				override={override}
				{...override('FetchPdfUrls')}
			/>
		</>}
	</Box>;
};

export default atomize(PaymentWrapper)({
	name: "PaymentWrapper",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "PaymentWrapper — my awesome component"
	},
	propInfo: {
		/*price: {
  title: "Price",
  control: "input",
  category: "Item"
  },*/
		itemName: {
			title: "Name",
			control: "input",
			category: "Item"
		},
		itemDescription: {
			title: "Description",
			control: "text-area",
			category: "Item"
		},
		currencyCode: {
			title: "Currency code",
			control: "input",
			category: "Main"
		},
		clientId: {
			title: "Client ID",
			control: "input",
			category: "Main"
		},
		priceBase: {
			title: "Min price",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 0.5
		},
		priceMax: {
			title: "Max price cap",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 0.5
		},
		priceAddon1: {
			title: "Addon1's price",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 1
		},
		priceAddon2: {
			title: "Addon2's price",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 1
		},
		priceAddon3: {
			title: "Addon3's price",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 1
		}
	}
}, {
	price: "1",
	currencyCode: "USD",
	clientId: "sb",
	priceBase: 0,
	priceMax: 100,
	priceAddon1: 0,
	priceAddon2: 0,
	priceAddon3: 0
});